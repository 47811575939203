exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acoustic-modems-js": () => import("./../../../src/pages/acoustic-modems.js" /* webpackChunkName: "component---src-pages-acoustic-modems-js" */),
  "component---src-pages-documentation-js": () => import("./../../../src/pages/documentation.js" /* webpackChunkName: "component---src-pages-documentation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-friends-js": () => import("./../../../src/pages/our-friends.js" /* webpackChunkName: "component---src-pages-our-friends-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-blog-article-page-js": () => import("./../../../src/templates/blog-article-page.js" /* webpackChunkName: "component---src-templates-blog-article-page-js" */)
}

